import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Header from '../components/header'
import Container from '../components/container'

import style from './legal.module.css'

const GDPRPage = () => {
  const data = useStaticQuery(graphql`
    query GDPRQuery {
      markdownRemark(frontmatter: {path: {eq: "/gdpr/" }}) {
        html
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          path
          title
        }
      }
    }
  `)

  const { markdownRemark: { html } } = data

  return(
    <Layout>
      <SEO
        title='GDPR - Privacy Policy and Data Protection'
        description="GDPR Policy for Tweeker, a simple ab and multivariate testing tool. We don't store or track any of your or your customers Personally Identifiable Information (PII)."
        canonical='https://tweeker.io/gdpr/'
      />
      <Header  />
      <Container>
        <div
          className={style.container}
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </Container>
    </Layout>
  )
}

export default GDPRPage